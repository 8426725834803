import * as React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout/layout'
import Seo from '../components/layout/seo'

const NotFoundPage = () => (
    <Layout>
        <Seo title="404: Not found" />
        <div className="my-6 p-8 bg-white">
            <article className="prose md:prose-sm max-w-none
                prose-h1:text-[#077ab3] prose-h1:uppercase prose-h1:font-bold prose-p:text-justify
                prose-a:text-[#0088cc] prose-a:font-bold prose-a:underline-offset-2 hover:prose-a:text-[#005580]">
                <h1>Hiába szimatolunk, nem találjuk ezt az oldalt!</h1>
                <p>
                    Talán nem veszett el, csak átalakult. Nézelődj az alsó és felső menüben,
                    vagy indulj el újra a <Link to="/">nyitóoldalról</Link>!
                </p>
            </article>
        </div>
    </Layout>
)

export default NotFoundPage
